import { gql } from '@chronosphereio/core';

export const GET_USER_SETTINGS = gql`
  query UserSettingsQuery($name: String!) {
    userSettingsValue(name: $name)
  }
`;

export const SET_USER_SETTINGS = gql`
  mutation UpdateUserSettingsMutation($input: UserSettingsValueInput!) {
    setUserSettingsValue(input: $input)
  }
`;
