import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { Provider as UrqlProvider } from 'urql';
import { BrowserRouter as Router } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import {
  initializeErrorTracking,
  GatewayConfigsContextProvider,
  FeatureFlagContextProvider,
  appendUserGuidingScript,
} from '@chronosphereio/core';
import { SnackbarProvider } from './context/Snackbar';
import { queryClient } from './query-client';
import { createUrqlClient, initNetworkMonitoring, setMuiXProLicenseKey } from '@/utils';
import { PageErrorBoundary } from '@/components';
import { App } from '@/App';
import { ThemeContextProvider } from '@/context/ThemeContextProvider';
import { ActiveResourceProvider } from '@/context/ActiveResourceContext';
import { CloudChartsProvider } from '@/context/CloudChartsProvider';
import { PersesTimeZoneProvider } from '@/context/PersesTimeZoneProvider';
import { UserTimeZoneProvider } from '@/context/TimeZone';
import { AnalyticsProvider } from '@/context/analytics/Analytics';
import { AppHeaderProvider } from '@/context/AppHeaderContext';
import { CloudAuthProvider } from '@/context/CloudAuthProvider';
import { ChronoAdminProvider, isPreviewDeploymentActive } from '@/admin';
import { ModalProvider } from '@/components/modal';

const APPLICATION_ID = 'root';

// Only initialize error tracking if we're not in a preview deployment
if (!isPreviewDeploymentActive()) {
  initializeErrorTracking();
  initNetworkMonitoring();
}

setMuiXProLicenseKey();
appendUserGuidingScript();

const rootEl = document.getElementById(APPLICATION_ID);
if (rootEl === null) {
  throw new Error('Root relement for react rendering not found');
}

const root = createRoot(rootEl);
root.render(
  <QueryClientProvider client={queryClient}>
    <UrqlProvider value={createUrqlClient()}>
      <Router>
        <CompatRouter>
          <QueryParamProvider adapter={ReactRouter5Adapter} options={{ includeAllParams: true, params: {} }}>
            <CloudAuthProvider>
              <UserTimeZoneProvider>
                <FeatureFlagContextProvider>
                  <ThemeContextProvider applicationRoot={`#${APPLICATION_ID}`}>
                    <SnackbarProvider>
                      <GatewayConfigsContextProvider>
                        <AnalyticsProvider>
                          <ActiveResourceProvider>
                            <CloudChartsProvider>
                              <PersesTimeZoneProvider>
                                <AppHeaderProvider>
                                  <PageErrorBoundary>
                                    <ChronoAdminProvider>
                                      <ModalProvider>
                                        <App />
                                      </ModalProvider>
                                    </ChronoAdminProvider>
                                  </PageErrorBoundary>
                                </AppHeaderProvider>
                              </PersesTimeZoneProvider>
                            </CloudChartsProvider>
                          </ActiveResourceProvider>
                        </AnalyticsProvider>
                      </GatewayConfigsContextProvider>
                    </SnackbarProvider>
                  </ThemeContextProvider>
                </FeatureFlagContextProvider>
              </UserTimeZoneProvider>
            </CloudAuthProvider>
          </QueryParamProvider>
        </CompatRouter>
      </Router>
    </UrqlProvider>
  </QueryClientProvider>
);
