import { useMemo } from 'react';
import { useFeatureFlags, useIsAuthenticated, useMutation, useQuery } from '@chronosphereio/core';
import {
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables,
  UserSettingsQuery,
  UserSettingsQueryContext,
  UserSettingsQueryVariables,
} from '@/generated/graphql';
import { GET_USER_SETTINGS, SET_USER_SETTINGS } from '@/queries';

const SEEN_FEATURES_KEY = 'seenFeatures';

export const useGetSeenFeatures = (pause = false) => {
  const [{ data, fetching, error }] = useQuery<UserSettingsQuery, UserSettingsQueryVariables>({
    query: GET_USER_SETTINGS,
    context: UserSettingsQueryContext,
    variables: { name: SEEN_FEATURES_KEY },
    pause,
  });

  if (error) {
    throw error;
  }

  const seenFeaturesSettings = useMemo(() => {
    return data?.userSettingsValue;
  }, [data?.userSettingsValue]);

  return { seenFeaturesSettings, loading: fetching };
};

export const useSetSeenFeatures = () => {
  const [, setSeenFeatures] = useMutation<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>(
    SET_USER_SETTINGS
  );

  return async (value: string[]) => await setSeenFeatures({ input: { name: SEEN_FEATURES_KEY, value } });
};

const USER_TIME_ZONE_KEY = 'timeZone';

export const useGetUserTimeZone = () => {
  const isAuthenticated = useIsAuthenticated();

  // Intentionally not catching and throwing errors. If something goes wrong fetching this information,
  // it will fall back to the `timeZoneSetting` being empty, which will result in the user's local
  // browser time zone being used.
  const [{ data, fetching }] = useQuery<UserSettingsQuery, UserSettingsQueryVariables>({
    query: GET_USER_SETTINGS,
    context: UserSettingsQueryContext,
    variables: { name: USER_TIME_ZONE_KEY },

    // Do not attempt to load the user's time zone until the user is authenticated because otherwise
    // the request will fail.
    pause: !isAuthenticated,
  });

  const timeZoneSetting = useMemo(() => {
    return data?.userSettingsValue;
  }, [data?.userSettingsValue]);

  return { timeZoneSetting, loading: fetching };
};

export const useSetUserTimeZone = () => {
  const [, setUserTimeZone] = useMutation<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>(
    SET_USER_SETTINGS
  );
  return async (value: string) => await setUserTimeZone({ input: { name: USER_TIME_ZONE_KEY, value } });
};

/**
 * Returns true when the user is allowed to modify their timezone preferences.
 */
export const useTimezonePreferencesEnabled = () => {
  const { enableNativeDashboards } = useFeatureFlags();

  // Timezone selection is gated by this flag because of a known issue with grafana-based dashboards and the
  // account-base time zone setting.
  return enableNativeDashboards === 'create-only-native-release';
};
