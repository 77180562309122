import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // react-query uses a default of 3 retries.
      // This sets the default to 0 retries, to optimize
      // for showing feedback to the user as quickly as possible.
      // If needed, the number of retries can be overridden in individual useQuery calls.
      retry: 0,
    },
  },
});
